import { render, staticRenderFns } from "./newsDetail.vue?vue&type=template&id=376a2481&scoped=true&"
import script from "./newsDetail.vue?vue&type=script&lang=js&"
export * from "./newsDetail.vue?vue&type=script&lang=js&"
import style0 from "./newsDetail.vue?vue&type=style&index=0&id=376a2481&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "376a2481",
  null
  
)

export default component.exports